import { Link } from 'gatsby';
import * as React from 'react';

type Anchor = { href: string; to?: never };
type GatsbyLink = { href?: never; to: string };
type ButtonLinkProps = {
  children: React.ReactNode;
  variant?: 'teal' | 'green' | 'brown' | 'brownLight';
  props?: unknown[];
} & (Anchor | GatsbyLink);

const VARIANT_MAP = {
  teal: 'bg-teal',
  green: 'bg-green',
  brown: 'bg-brown',
  brownLight: 'bg-brown-light',
};

function ButtonLink({
  children,
  href,
  to,
  variant = 'teal',
  ...props
}: ButtonLinkProps): React.ReactElement {
  const className = `${VARIANT_MAP[variant]} inline-flex items-center px-6 py-3 text-base font-semibold text-white border border-transparent rounded-full shadow-sm focus:outline-none focus:ring-2`;
  if (href) {
    return (
      <a href={href} className={className} {...props}>
        {children}
      </a>
    );
  }
  return (
    <Link to={to as string} className={className} {...props}>
      {children}
    </Link>
  );
}
export { ButtonLink };
