import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { ContactSection } from '../components/contact-section';
import { Hero } from '../components/hero';
import { Layout } from '../components/layout';
import { SEO } from '../components/seo';
import { SideBySide } from '../components/side-by-side';
import { ButtonLink } from '../components/ui/button-link';

// TODO: set rooms dynamically

function ChildCarePage(): React.ReactElement {
  return (
    <>
      <SEO title="Child Care Page" />
      <Layout>
        <Hero
          image={
            <StaticImage
              src="../images/child-care-hero.jpg"
              alt=""
              className="flex-1 object-cover"
            />
          }
          heading={
            <h1 className="text-6xl">
              <span className="font-semibold">
                Quality Early <br />
                Childhood Education <br />
              </span>
              in Lake Cathie
            </h1>
          }
          cta={
            <ButtonLink variant="teal" to="#contact">
              Enquire Today
            </ButtonLink>
          }
        />
        <WombatRoom />
        <GumnutRoom />
        <JoeyRoom />
        <KangaRoom />
        <ContactSection />
      </Layout>
    </>
  );
}

function WombatRoom(): React.ReactElement {
  return (
    <div className="py-12">
      <SideBySide>
        <SideBySide.TwoCols>
          <StaticImage
            src="../images/child-care-one.png"
            alt=""
            className="w-[16rem] sm:w-[24rem] md:w-auto h-auto mx-auto"
          />
        </SideBySide.TwoCols>
        <SideBySide.ThreeCols>
          <h2 className="text-6xl">
            <span className="font-semibold">The Wombat </span>
            Room
          </h2>
          <div className="mt-5 prose">
            <p>
              In the Wombats Room, we focus on providing secure and loving
              relationships between educators and children to foster every
              child's confidence and potential explore, learn and develop...
            </p>
          </div>
          <div className="mt-5">
            <p>
              <ButtonLink variant="green" to="/rooms/wombat/">
                Read more
              </ButtonLink>
            </p>
          </div>
        </SideBySide.ThreeCols>
      </SideBySide>
    </div>
  );
}

function GumnutRoom(): React.ReactElement {
  return (
    <div className="py-12">
      <SideBySide>
        <SideBySide.ThreeCols>
          <h2 className="text-6xl text-right">
            <span className="font-semibold">The Gumnut </span>
            Room
          </h2>
          <div className="mt-5 prose text-right">
            <p>
              In the Gumnut Room, we aim to provid a secure and loving
              environment, where educators thrive to build trusting
              relationships with each child. We believe the foundation for all
              children's...
            </p>
          </div>
          <div className="mt-5 text-right">
            <p>
              <ButtonLink variant="teal" to="/rooms/gumnut/">
                Read more
              </ButtonLink>
            </p>
          </div>
        </SideBySide.ThreeCols>
        <SideBySide.TwoCols>
          <StaticImage
            src="../images/child-care-two.png"
            alt=""
            className="w-[16rem] sm:w-[24rem] md:w-auto h-auto mx-auto"
          />
        </SideBySide.TwoCols>
      </SideBySide>
    </div>
  );
}

function JoeyRoom(): React.ReactElement {
  return (
    <div className="py-12">
      <SideBySide>
        <SideBySide.TwoCols>
          <StaticImage
            src="../images/child-care-three.png"
            alt=""
            className="w-[16rem] sm:w-[24rem] md:w-auto h-auto mx-auto"
          />
        </SideBySide.TwoCols>
        <SideBySide.ThreeCols>
          <h2 className="text-6xl">
            <span className="font-semibold">The Joey </span>
            Room
          </h2>
          <div className="mt-5 prose">
            <p>
              Relationships in the Joey's room are a key influence to everything
              we do within the curriculum. The educators see the importance of
              building nurturing...
            </p>
          </div>
          <div className="mt-5">
            <p>
              <ButtonLink variant="brown" to="/rooms/joey">
                Read more
              </ButtonLink>
            </p>
          </div>
        </SideBySide.ThreeCols>
      </SideBySide>
    </div>
  );
}

function KangaRoom(): React.ReactElement {
  return (
    <div className="py-12">
      <SideBySide>
        <SideBySide.ThreeCols>
          <h2 className="text-6xl text-right">
            <span className="font-semibold">The Kanga </span>
            Room
          </h2>
          <div className="mt-5 prose text-right">
            <p>
              Children's idas and theories of the world around them are
              constantly growing and changing and in the Kanga's room, we
              recognnise the endless learning...
            </p>
          </div>
          <div className="mt-5 text-right">
            <p>
              <ButtonLink variant="brownLight" to="/rooms/kanga">
                Read more
              </ButtonLink>
            </p>
          </div>
        </SideBySide.ThreeCols>
        <SideBySide.TwoCols>
          <StaticImage
            src="../images/child-care-four.jpg"
            alt=""
            className="w-[16rem] sm:w-[24rem] md:w-auto h-auto mx-auto"
          />
        </SideBySide.TwoCols>
      </SideBySide>
    </div>
  );
}

export default ChildCarePage;
