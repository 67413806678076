import * as React from 'react';

import { Wave } from '../icons/wave';
import { ContactDetails } from './contact-details';
import { ContactForm } from './contact-form';

function ContactSection(): React.ReactElement {
  return (
    <div className="relative" id="contact">
      <Wave className="text-green-light" />
      <div className="px-4 py-12 sm:px-6 lg:px-8 bg-green-light">
        <div className="w-full max-w-screen-lg mx-auto">
          <h2 className="text-4xl text-center md:text-left">
            Get in touch <br />
            <span className="font-semibold">Today</span>
          </h2>
          <div className="grid gap-8 mt-6 lg:grid-cols-2">
            <ContactForm />
            <ContactDetails />
          </div>
        </div>
      </div>
      <div className="bg-green-light">
        <Wave className="text-white" />
      </div>
    </div>
  );
}

export { ContactSection };
